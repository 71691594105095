<app-topbar moduleSlug="website">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Configurations</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
      [routerLink]="['/website/settings/app-settings/edit']">
      <i class="material-icons">library_add</i>
      Créer une nouvelle configuration
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">settings</i>
        </div>
        <h4 class="card-title">Configurations</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable class="material" [rows]="appSettings" [columnMode]="'standard'" [rowHeight]="'auto'"
            [headerHeight]="50" [footerHeight]="50" [loadingIndicator]="loadingList">
            <ngx-datatable-column name="ID" prop="id" [width]="75">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Clé unique" prop="key" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Valeur" prop="value" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | truncate: 100 }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Contenu" prop="content" [width]="250">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span [title]="value">{{ value | truncate: 60 }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Fichier" prop="asset_image" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [href]="value" target="_blank" *ngIf="value" [download]="row.id + '-' + row.key">Télécharger</a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="À traiter le" prop="process_date_time" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date:'EEEE dd MMMM y'}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="['/website/settings/app-settings/edit/', row.id]" type="button" class="btn btn-success"
                  title="Modifier la configuration">
                  <i class="material-icons">edit</i>
                </a>
                <button type="button" class="btn btn-danger" title="Supprimer">
                  <i class="material-icons" (click)="onDelete(row.id)">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>